<template>
  <div>
    <RecordAudio @saved="addQuestion" :media-prop="question && question.media ? question.media : null">
      <template #header="{ saving }">
        <div class="flex items-center justify-between">
          <h4 class="font-poppins text-lg font-bold text-blue-800">Record Audio</h4>

          <div>
            <button @click="$emit('close')" type="button">
              <XIcon class="h-5 text-gray-500" />
            </button>
          </div>
        </div>
        <div class="mt-4 mb-6">
          <input
            v-model="body"
            type="text"
            class="form-input rounded-full focus:border-pink-500 focus:shadow-none py-3 px-4 w-full disabled:bg-guestio-gray-100 disabled:text-gray-500"
            maxlength="255"
            placeholder="Write your question here..."
            ref="question"
            :disabled="saving">
        </div>
      </template>

      <template #footer="{ redo, saving, save }">
        <div class="flex items-center justify-around pt-4" :class="{'opacity-75': saving}">
          <template v-if="saving">
            <div class="flex items-center justify-center py-3">
              <loading-icon class="h-2 text-pink-500"/>
            </div>
          </template>

          <template v-else>
            <button @click.prevent="redo" type="button" class="flex items-center space-x-2 text-pink-500">
              <RedoIcon class="w-4 h-4" />
              <span>Redo</span>
            </button>

            <button
              @click="save"
              type="button" class="bg-indigo-gradiant text-white font-bold px-10 py-3 rounded-full flex items-center justify-center hover:opacity-90 disabled:opacity-50"
            >
              Save Question
            </button>
          </template>
        </div>
      </template>
    </RecordAudio>
  </div>
</template>

<script>
  import RedoIcon from '@/components/svgs/RedoIcon'
  import XIcon from '@/components/svgs/XIcon'
  import RecordAudio from '@/components/shared/RecordAudio'

  export default {
    props: {
      question: Object,
    },

    components: {
      RedoIcon,
      XIcon,
      RecordAudio,
    },

    data() {
      return {
        body: '',
      }
    },

    methods: {
      addQuestion(media) {
        this.$emit('update-question', {
          body: this.body,
          media,
        })

        this.body = ''
        this.$emit('close')
      }
    },

    mounted() {
      if (this.question) {
        this.body = this.question.body
      }
    }
  }
</script>
