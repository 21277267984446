<template>
  <div class="min-h-full flex flex-col">
    <div>
      <div class="flex justify-between mb-9">
        <h4 class="text-3xl sm:text-4.5xl font-bold mt-1">
          MINIVIEW™ Questions
        </h4>
        <!-- Step counter -->
        <StepsCounter class="ml-8" v-if="steps.length" :stepCount="steps.length -1" :currentStep="currentStep"/>
      </div>
    </div>
    <div class="text-gray-500 font-roboto text-lg">
      These questions will be shown to anyone who has the MINIVIEWs™ link.
    </div>

    <div class="mt-12 space-y-12">
      <ValidationObserver ref="miniviewQuestionsForm">
        <form method="post" @submit.prevent="saveQuestion">
          <ValidationProvider mode="eager" rules="required" name="Question" :vid="`question`" v-slot="{ errors }" tag="div">
            <div class="relative">
              <label for="question" class="sr-only">Add Question</label>
              <input type="text" id="question" name="question" class="form-input rounded-full focus:border-pink-500 focus:shadow-none py-3 px-4 w-full" :class="[{'border-red-500': errors.length}, {'cursor-not-allowed disabled': questions.length == maxQuestions }]" placeholder="Write your question here..." v-model="form.question">
              <div class="absolute right-0 inset-y-0 flex items-center justify-center mr-2">
                <button type="submit" class="rounded-full h-9 w-9 bg-indigo-gradiant text-white flex justify-center items-center p-2" :class="questions.length == maxQuestions ? 'cursor-not-allowed disabled' : 'hover:opacity-75'" title="Add Question">
                  <PlusIcon class="w-full h-full" />
                </button>
              </div>
            </div>
            <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
          </ValidationProvider>

          <div class="mt-4 flex sm:flex-row flex-col items-start sm:items-center space-x-0 sm:space-x-4 space-y-4 sm:space-y-0">
            <modal :with-close-button="false" class="bg-pink-500 text-sm text-blue-800 bg-opacity-10 flex items-center px-6 py-3 rounded-full hover:bg-opacity-20 focus:shadow-outline">
              <VideoIcon class="text-pink-500 h-4" />
              <span class="ml-3">Record a video</span>

              <template #content="{ close }">
                <div class="max-w-xl w-full bg-white rounded-xl overflow-auto my-4 p-6 lg:px-8 max-h-screen-10">
                  <RecordVideoQuestion @close="close" @update-question="addQuestion" />
                </div>
              </template>
            </modal>

            <modal class="bg-pink-500 text-sm text-blue-800 bg-opacity-10 flex items-center px-6 py-3 rounded-full hover:bg-opacity-20 focus:shadow-outline">
              <MicIcon class="text-pink-500 h-4" />
              <span class="ml-3">Record an audio</span>

              <template #content="{ close }">
                <div class="max-w-xl w-full bg-white rounded-xl overflow-auto my-4 p-6 lg:px-8 max-h-screen-10">
                  <RecordAudioQuestion @close="close" @update-question="addQuestion" />
                </div>
              </template>
            </modal>
          </div>
        </form>
      </ValidationObserver>

      <div>
        <h4 class="text-2xl text-blue-800">
          <span class="font-bold">{{ questions.length }}</span> of {{ maxQuestions }} questions
        </h4>

        <div class="mt-4">
          <div v-if="! questions.length" class="h-12 mb-2 flex justify-center items-center text-sm text-gray-500 bg-gray-50 rounded-lg border-2 border-dashed opacity-75">
            No questions added.
          </div>

          <div v-else class="space-y-4">
            <div v-for="(question, index) in questions" :key="`question-${index}`" class="border-2 border-guestio-gray-100 px-4 py-3 bg-white booking-option-shadow rounded-lg">
              <div class="flex justify-between">
                <div class="flex items-start">
                  <DotsIcon class="h-4 w-4 flex-shrink-0 mt-1.5" />
                  <h5 class="ml-3 text-lg font-bold text-blue-800">
                    {{ index + 1 }}. {{ question.body }}
                  </h5>
                </div>
                <div>
                  <ConfirmDialog
                    @confirm="removeQuestion(index, $event)"
                    confirm-action="Yes, Delete"
                    dialog-title="Delete Question?"
                    dialog-text="Are you sure you want to delete this question?"
                    class="text-red-500 mt-1.5"
                  >
                    <TrashIcon class="h-5 w-5" />
                  </ConfirmDialog>
                </div>
              </div>

              <div v-if="question.media" class="mt-3 flex justify-between items-center space-x-4">
                <template v-if="question.media.collection_name == 'audio_recordings'">
                  <AudioPreview :media-src="question.media.url" class="flex-1" />

                  <modal ref="previewModal" :with-close-button="false" class="text-pink-500 space-x-2 flex items-center text-sm">
                    <EditAltIcon class="h-5 w-5" />
                    <span>Edit</span>

                    <template #content="{ close }">
                      <div class="max-w-xl w-full bg-white rounded-xl overflow-auto my-4 p-6 lg:px-8 max-h-screen-10">
                        <RecordAudioQuestion @close="close" :question="question" @update-question="updateQuestion(index, $event)" />
                      </div>
                    </template>
                  </modal>
                </template>

                <template v-else-if="question.media.collection_name == 'video_recordings'">
                  <div class="w-32 flex-shrink-0">
                    <div class="overflow-hidden relative pb-2/3 rounded-lg">
                      <div class="absolute inset-0 z-10 bg-red-300">
                        <video
                          class="w-full h-full object-cover"
                          playsinline
                          looping="false"
                          :src="question.media.url"
                        ></video>
                      </div>
                      <div class="absolute inset-0 z-20 flex items-center justify-center">
                        <button type="button" @click="$refs.previewModal[index].toggle()">
                          <PlayAltIcon class="h-6 w-6 text-white" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div>
                    <modal ref="previewModal" :with-close-button="false" class="text-pink-500 space-x-2 flex items-center text-sm">
                      <EditAltIcon class="h-5 w-5" />
                      <span>Edit</span>

                      <template #content="{ close }">
                        <div class="max-w-xl w-full bg-white rounded-xl overflow-auto my-4 p-6 lg:px-8 max-h-screen-10">
                          <RecordVideoQuestion @close="close" :question="question" @update-question="updateQuestion(index, $event)" />
                        </div>
                      </template>
                    </modal>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="flex justify-center">
          <button @click="saveMiniviewQuestions" type="button" class="bg-indigo-gradiant text-white px-28 h-16 rounded-full font-bold text-xl font-roboto uppercase" :class="{'opacity-50 cursor-not-allowed': loading || questions.length == 0}" :disabled="loading || questions.length == 0">
            <span v-if="loading">
              <loading-icon class="h-5 w-5"/>
            </span>
            <span v-else>Continue</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
  import StepsCounter from '@/components/StepsCounter';
  import ConfirmDialog from '@/components/shared/ConfirmDialog';
  import Modal from '@/components/shared/Modal';
  import PlusIcon from '@/components/svgs/PlusIcon';
  import EditAltIcon from '@/components/svgs/EditAltIcon';
  import TrashIcon from '@/components/svgs/TrashIcon';
  import DotsIcon from '@/components/svgs/DotsIcon';
  import VideoIcon from '@/components/svgs/VideoIcon';
  import MicIcon from '@/components/svgs/MicIcon';
  import PlayAltIcon from '@/components/svgs/PlayAltIcon';
  import RecordVideoQuestion from '@/components/Book/guest/RecordVideoQuestion';
  import RecordAudioQuestion from '@/components/Book/guest/RecordAudioQuestion';
  import AudioPreview from '@/components/shared/AudioPreview';

  export default {
    name: 'MiniviewQuestions',
    components: {
      StepsCounter,
      ConfirmDialog,
      Modal,
      EditAltIcon,
      PlusIcon,
      TrashIcon,
      DotsIcon,
      VideoIcon,
      MicIcon,
      PlayAltIcon,
      RecordVideoQuestion,
      RecordAudioQuestion,
      AudioPreview, },

    data() {
      return {
        loading: false,
        form: {
          question: ''
        },

        questions: [],
        maxQuestions: 10,
      }
    },

    computed: {
      ...mapGetters({
        steps: 'miniviews/steps',
        title: 'miniviews/title',
      }),

      ...mapState({
        currentStep: state => state.miniviews.currentStep,
      }),
    },
    
    mounted() {
      if (this.title == '') {
        this.$router.push({name: this.steps[0]})
      }
    },

    methods: {
      ...mapActions({
        createMiniview: 'miniviews/create'
      }),

      ...mapMutations({
        incrementStep: "miniviews/incrementStep",
      }),

      saveQuestion() {
        if (! this.form.question.length) {
          this.$refs.miniviewQuestionsForm.setErrors({question: ['Write your question first']})
          return
        }

        this.addQuestion({
          body: this.form.question
        })

        this.form.question = ''
        this.$refs.miniviewQuestionsForm.reset();
      },

      addQuestion(question) {
        this.questions.push(question)
      },

      updateQuestion(index, question) {
        this.questions.splice(index, 1, question)
      },

      removeQuestion(index, callback) {
        this.questions.splice(index, 1)
        callback()
      },

      async saveMiniviewQuestions() {
        try {
          this.loading = true;
          const { success } = await this.createMiniview({ title: this.title, questions: this.questions});
          if (success) {
            this.nextStep();
          }
        } catch (error) {
          console.log(error);
          this.$toast.error(error.response.data.message)
        } finally {
          this.loading = true;
        }
      },

      nextStep() {
        this.$router.push({ name: this.steps[this.currentStep] })
        this.incrementStep();
      },
    },
  }
</script>
